import React, { memo, useEffect, useState } from 'react';
import { useStoreActions } from 'store/store';
import { ConnectPayouts, ConnectComponentsProvider } from "@stripe/react-connect-js";
import {loadConnectAndInitialize} from '@stripe/connect-js';
import restService from 'services/rest.service';

import { useViewport } from 'use-viewport';

import PageContainer from 'common/layout/PageContainer';
import TextInputPlaceholder from 'common/components/Placeholder/TextInput';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';

import PageLayout from 'common/components/PageLayout/PageLayout';

import { footerHeight, footerMobileHeight } from 'utils/constants';

const quickLinks = [
  {
    title: 'Accounting Management',
    link: '/artist/accounting/management',
  },
  {
    title: 'Artist Dashboard',
    link: '/artist/dashboard',
  },
  {
    title: 'Manage Releases',
    link: '/artist/releases',
  },
  {
    title: 'Insights & Analytics',
    link: '/artist/insights',
  },
  {
    title: 'Artist Profile',
    link: '/artist/settings',
  },
  {
    title: 'Artist Support (A&R)',
    link: '/support',
  },
];
/*
const stripePromises = {
  GB: process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY_GB || "a",
  US: process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY_US || "a",
}
*/


const ArtistAccountingPayout = () => {
  const viewport = useViewport();

  const [stripeConnectInstance] = useState(() => {
    
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const response = await restService.artistStripePayout();

      setLoading(false);
      return response.client_secret;
  
    }

    return loadConnectAndInitialize({
      // This is a placeholder - it should be replaced with your publishable API key.
      // Sign in to see your own test API key embedded in code samples.
      // Don’t submit any personally identifiable information in requests made with this key.
      publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY_GB || "a",
      fetchClientSecret: fetchClientSecret,
      appearance: {
        variables: {
          colorPrimary: "#FFD600",
          colorText: "#FFF",
          colorBackground: "#000",
          buttonPrimaryColorBackground: "#FFD600",
          buttonPrimaryColorBorder: "#FFD600",
          buttonSecondaryColorBackground: "#FFD600",
          buttonSecondaryColorText: "#000",
          buttonBorderRadius: "20px",
          spacingUnit: "8px",
        },
      },
    })
  });

  const [loading, setLoading] = useState<boolean>(true);

  const setMainFooterHidden = useStoreActions(
    (state) => state.style.setMainFooterHidden,
  );

  useEffect(() => {
    setMainFooterHidden(true);

    return () => {
      setMainFooterHidden(false);
    };
  }, []);


  const secondColumn = (
    <>
      {loading ? (
        <>
          <TextInputPlaceholder />
          <TextInputPlaceholder />
          <TextInputPlaceholder />
          <TextInputPlaceholder />
          <TextInputPlaceholder />
        </>
      ) : (
        <>
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectPayouts />
          </ConnectComponentsProvider>
        </>
      )}
    </>
  );

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}
      pageTitle={'Accounting Payouts | SongBits'}>
      <PageLayout
        title="Payouts"
        is2ColumnLayout
        sections={[
          {
            content: secondColumn,
          },
          {
            content:
              viewport.width > 576 && !loading ? (
                <QuickLinks links={quickLinks} />
              ) : (
                <div style={{ marginTop: '-22px' }}>
                  <QuickLinksPlaceholder noLinks={5} />
                </div>
              ),
          },
        ]}
      />
    </PageContainer>
  );
};

export default memo(ArtistAccountingPayout);
