import React, { useCallback } from 'react';
import { useViewport } from 'use-viewport';

import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';

import Banner from '../assets/investors_bg.png';
import LinkedInIcon from 'common/icons/LinkedIn.icon'
import { ContactButton, ContentButton } from './styled/common.styled';

import { footerHeight, footerMobileHeight, xlg, sm } from 'utils/constants';

import { useIntercom } from 'react-use-intercom';
import {
  HeadContainer,
  HeadingSection,
  Image,
  ImageContainer,
  ImageGradient,
  TextContent,
  ButtonsContainer,
  OrText,
  TextContainer,
  TextContentHeader,
  TextContentMain
} from './styled/Investors.styled';

import { Header, Title } from './styled/Company.styled';

import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';

const Investors = () => {
  const viewport = useViewport();

  const { show, boot } = useIntercom();

  function toggleIntercom() {
    boot();
    show();
  }

  const buttonsHeight = useCallback(
    () => (viewport.width < xlg ? 45 : 55),
    [viewport.width],
  );

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}>
      <ImageContainer>
        <Image src={Banner} />
        <ImageGradient width={'25'} banner={Banner} />
      </ImageContainer>
      <HeadContainer>
        <HeadingSection>
          <Header>
            <Title
              text="investors"
              fontColor={theme.colors.white}
              letterSpacing={'3px'}
            />
          </Header>

        <Spacer height={viewport.width < sm ? 10 : 40}></Spacer>

        <TextContainer>
            <TextContentHeader>
            <Typography
                fontSize={"fz24"}
                lineHeight={"28px"}
                fontColor={theme.colors.yellow}
                letterSpacing="-1px"
                text={'Participate in web3 music’s fastest growing start\nup with a very bright future'}
            />
            </TextContentHeader>
            <Spacer height={25} />
            
            <TextContentMain>
            <TextContent>
            We are the movers and shakers; with unparalleled access to {'\n'} game changing Producers and some of the world’s biggest Artists.  
            </TextContent>
            <Spacer height={24} />
            <TextContent>
            With a experienced board and industry professionals from music, blockchain and finance including Dave Stewart (Rock n Roll Hall of Fame, Grammy Winning Producer, Eurythmics) SongBits is positioned to take affordable music ownership to the fans on a global scale. Reach out to discuss joining us on our journey.
            </TextContent>
            </TextContentMain>
            <Spacer height={30} />
        </TextContainer>

        <ButtonsContainer>
            <div style={{display: 'flex', gap: '30px', verticalAlign: 'middle'}}>
                <ContactButton
                    height={buttonsHeight()}
                    borderRadius={50}
                    borderColor={theme.colors.yellow}
                    onClick={() => {
                        toggleIntercom();
                    }}
                    label={
                        <div style={{ display: 'flex' }}>
                            <ContentButton
                            text="contact"
                            fontSize="fz16"
                            fontWeight="bold"
                            />
                            &nbsp;
                            <ContentButton
                            text="us"
                            fontSize="fz16"
                            fontColor={theme.colors.yellow}
                            fontWeight="bold"
                            />
                        </div>
                    }
                />

                <OrText
                    text="or"
                    fontSize="fz24"
                    lineHeight='24px'
                    fontColor={theme.colors.white}
                />

                <a href="https://uk.linkedin.com/company/songbits" rel="noreferrer" target='_blank'>
                    <LinkedInIcon />
                </a>

            </div>
        </ButtonsContainer>
            
        </HeadingSection>
      </HeadContainer>
    </PageContainer>
  );
};

export default WithRouteAnimation(Investors);