import React, { FC, memo, useEffect, useState } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import { CardSchema, PaymentDetails } from '../types';
import styled from 'styled-components';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import { useViewport } from 'use-viewport';
import restService from 'services/rest.service';
import { useStoreState, useStoreActions } from 'store/store';
import { BeatLoader } from 'react-spinners';
import CVVModal from 'modules/account/pages/components/Modals/CVVModal';
import { useAppDispatch } from 'hooks/reduxToolkit';
import { setModalType, setShowState } from 'store-persist/reducers/modalSlice';
import { useAppSelector } from 'hooks/reduxToolkit';
import { checkStripePayError } from 'utils/stripe-errors';
import { getTopPosition } from 'utils/functions';
import ExpressCheckout from 'modules/payments/PaymentModals/ExpressCheckout';
import {
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import {
  loadStripe,
  StripeElementsOptionsMode
} from '@stripe/stripe-js';
import { StripeCVVResponse } from "utils/stripe";

const stripePromises = {
  GB: loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY_GB || ""),
  US: loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY_US || ""),
}


interface PaymentModalProps {
  isOpen: boolean;
  mostRecentCard: CardSchema;
  onClose: () => void;
  onCardPayment: () => void;
  onAddCard: (previousCard?: string) => void;
  purchaseExpired: () => void;
  onPaymentComplete: (id: string) => void;
  onPaymentFail: (text: string) => void;
  onStartAgain: () => void;
  header: JSX.Element;
  cost: string;
  artistPlatform: "GB" | "US";
}

const CardAuto: FC<PaymentModalProps> = ({
  isOpen,
  header,
  mostRecentCard,
  onClose,
  onPaymentComplete,
  onPaymentFail,
  onStartAgain,
  onAddCard,
  purchaseExpired,
  artistPlatform,
}) => {
  const viewport = useViewport();
  const dispatch = useAppDispatch();

  const { expiryTime, dataState } = useAppSelector((state) => state.modalSlice);

  const [isAwaitingPaymentConfirmation, setIsAwaitingPaymentConfirmation] =
    useState<boolean>(false);
  const [show3dsFrame, setShow3dsFrame] = useState<boolean>(false);
  const [threeDSLink, set3DSLink] = useState<string>();
  const [threeDSFlowInProgress, setThreeDSFlowInProgress] = useState<boolean>(false);
  const [showCvvModal, setShowCvvModal] = useState<boolean>(false);
  const [canShowCloseButton, setCanShowCloseButton] = useState<boolean>(true);
  const [showStripeCheckoutWrapper, setShowStripeCheckoutWrapper] = useState<boolean>(false);
  const [showCardWrapper, setShowCardWrapper] = useState<boolean>(false);
  const [showTabLoader, setShowTabLoader] = useState<boolean>(false);
  const [expressCheckoutOptions, setExpressCheckoutOptions] = useState<StripeElementsOptionsMode | null>(null);
  const [activePaymentMethods, setActivePaymentMethods] = useState<number>(1);
  const [stripeTokenPromise, setStripeTokenPromise] = useState<any>(null);

  const [purchaseIsExpired, setPurchaseIsExpired] = useState<boolean>(false);

  const threeDSecureTrigger = useStoreState(
    (actions) => actions.purchase.threeDSecure,
  );
  const resetThreeDSecureTrigger = useStoreActions(
    (actions) => actions.purchase.resetThreeDSecure,
  );

  const [showExceededAttempts, setShowExceededAttempts] =
    useState<boolean>(false);

  const setCreatePayment = useStoreActions(
    (actions) => actions.data.setCreatePayment,
  );

  const [modalWidth] = useState<number>(434);

  const getTokenPaymentPromise = () => {
    return stripePromises[artistPlatform];
  }

  useEffect(() => {
    if (isOpen) {
      (window as any).gtag('event', 'purchase_card', {});
    } else {
      //stopApplePayTimeout();
    }

    if (expiryTime > 0 && expiryTime < new Date().getTime()) {
      purchaseExpired();
      setPurchaseIsExpired(true);
    } else if (isOpen) {
      dispatch(setModalType('CardAuto'));
      dispatch(setShowState(isOpen));
      setStripeTokenPromise(getTokenPaymentPromise())
      setExpressCheckoutOptions({
        mode: 'payment',
        amount: parseInt(dataState.costOfSelected.replace(".", "")),
        currency: 'usd',
        // Customizable with appearance API.
        appearance: {
          variables: {
            borderRadius: "50px",
            spacingUnit: "12.5px"
          },
        },
      })
      setShowTabLoader(true);
    } else if (!isOpen) {
      setExpressCheckoutOptions(null);
      setShowStripeCheckoutWrapper(false);
      setShowTabLoader(false);
    }
  }, [isOpen]);

  const startPolling = () => {
    const id = window.localStorage.getItem('paymentId') || "";

    let poll: NodeJS.Timer;
    // Poll payment endpoint
    setTimeout(() => {
      poll = setInterval(() => {
        // every 5 seconds
        restService
          .getPayment(id)
          .then((res) => {
            if (res.status === "pending") {
              //clearInterval(poll);
              //set3DSLink(res.redirect_url);
              //setShow3dsFrame(true);
              //setCreatePayment(res);
              // do nothing
            } else if (res.status === 'complete') {
              setCanShowCloseButton(true);
              setThreeDSFlowInProgress(false);
              clearInterval(poll);
              setIsAwaitingPaymentConfirmation(false);
              onPaymentComplete(id);
              setCreatePayment(res);
            } else if (res.status === 'failed') {
              setCanShowCloseButton(true);
              setThreeDSFlowInProgress(false);
              clearInterval(poll);
              setCreatePayment(res);
              onFailedPurchaseAttempt(res);
            }
          })
          .catch(() => {
            setCanShowCloseButton(true);
            setThreeDSFlowInProgress(false);
            setIsAwaitingPaymentConfirmation(false);
            onPaymentFail('');
            clearInterval(poll);
          });
      }, 3000);
    }, 2000);
  }

  useEffect(() => {
    if (threeDSecureTrigger && threeDSecureTrigger === "payment") {

      setShow3dsFrame(false);
      setIsAwaitingPaymentConfirmation(true);

      startPolling();

      resetThreeDSecureTrigger();
    }
  }, [threeDSecureTrigger]);

  function onFailedPurchaseAttempt(res: any) {
    const purchaseAttempt = window.localStorage.getItem('purchase_attempt');

    if (!purchaseAttempt) {
      window.localStorage.setItem('purchase_attempt', '1');
    }

    if (Number(purchaseAttempt) >= 3) {
      setIsAwaitingPaymentConfirmation(false);
      setShowExceededAttempts(true);
      window.localStorage.removeItem('purchase_attempt');
    } else {
      const currentAttempt = window.localStorage.getItem('purchase_attempt');
      if (currentAttempt) {
        window.localStorage.setItem(
          'purchase_attempt',
          String(parseInt(currentAttempt) + 1),
        );
      }

      let error_string = '';
      error_string = checkStripePayError(
        res
      );
      onPaymentFail(error_string);
    }
  }


  const onStripeCheckoutReady = (event: any) => {

    console.log("onStripeCheckoutReady", event)

    const methods = event.availablePaymentMethods;

    let total_available = 1;

    if ("applePay" in methods && methods.applePay === true) {
      total_available += 1;
    }

    if ("googlePay" in methods && methods.googlePay === true) {
      total_available += 1;
    }

    if ("paypal" in methods && methods.paypal === true) {
      total_available += 1;
    }

    if ("link" in methods && methods.link === true) {
      total_available += 1;
    }

    if (mostRecentCard && mostRecentCard.cardId) {
      total_available += 1;
    }

    setActivePaymentMethods(total_available)
    setShowTabLoader(false);
    setShowStripeCheckoutWrapper(true);
    setShowCardWrapper(true);

  }

  async function submitHandler(encryptedCvv: StripeCVVResponse) {

    setCanShowCloseButton(false);

    const paymentDetails: PaymentDetails = {
      purchaseId: window.localStorage.getItem('purchaseId') || "",
      card: {
        cardId: mostRecentCard.cardId,
        cvv: encryptedCvv,
      },
    };

    setIsAwaitingPaymentConfirmation(true);
    restService
      .makeCardPayment(paymentDetails)
      .then((res) => {
        if (res.status === "pending") {
          setThreeDSFlowInProgress(true);
          set3DSLink(res.redirect_url);
          setShow3dsFrame(true);
          window.localStorage.setItem('paymentId', res.paymentid);
          setCreatePayment(res);
        } else if (res.status === "complete") {
          setCanShowCloseButton(true);
          setIsAwaitingPaymentConfirmation(false);
          onPaymentComplete(res.id);
          setCreatePayment(res);
        } else if (res.status === "failed") {
          setCanShowCloseButton(true);
          setCreatePayment(res);
          onFailedPurchaseAttempt(res);
        }
      })
      .catch((error: any) => {
        error
        setCanShowCloseButton(true);
        onPaymentFail('');
        //purchaseExpired();
      });
  }

  function main_content() {
    return (
      <PaymentRow style={{ display: 'flex', flexDirection: 'column' }}>
        {activePaymentMethods <= 3 && (
          <><Spacer height={25} /></>
        )}
        {showTabLoader && (
            loading_stripe()
        )}
        {!purchaseIsExpired && (
          <>
            {expressCheckoutOptions !== null && (
              <Elements stripe={stripeTokenPromise} options={expressCheckoutOptions}>
                <div style={{width: '352px', display: showStripeCheckoutWrapper ? 'block' : 'none'}}>
                  <ElementsConsumer>
                    {({ stripe, elements }) => (
                      <ExpressCheckout
                        stripe={stripe}
                        elements={elements}
                        onReady={onStripeCheckoutReady}
                        onClick={(event) => { 
                          setCanShowCloseButton(false);
                          setShowStripeCheckoutWrapper(false);
                          setShowTabLoader(true);
                          event.resolve();
                        }}
                        onError={(err) => {
                          setCanShowCloseButton(true);
                          setShowTabLoader(false);
                          setShowStripeCheckoutWrapper(true);
                          onFailedPurchaseAttempt({
                            message: err
                          });
                        }}
                        onCancel={(reason) => {
                          reason
                          setCanShowCloseButton(true);
                          setShowTabLoader(false);
                          setShowStripeCheckoutWrapper(true);
                          onPaymentFail("Payment modal cancelled by user");
                        }}
                        onSuccess={() => {
                          setCanShowCloseButton(true);
                          setShowTabLoader(false);
                          setShowStripeCheckoutWrapper(true);
                          onPaymentComplete("");
                        }}
                      />
                    )}
                  </ElementsConsumer>
                </div>
              </Elements>
            )}
          </>
        )}
        {mostRecentCard && mostRecentCard.cardId ? (
          <>
            <div style={{width: '352px', display: showCardWrapper ? 'block' : 'none'}}>
              <PayWithCardButton
                className="pay-with-card-button"
                height={45}
                borderRadius={50}
                bgColor={theme.colors.black}
                labelColor={theme.colors.white}
                borderColor={theme.colors.white}
                autoFocus={false}
                tabIndex={1001}
                label={
                  <TextContent
                    text={`Pay using saved card ending ${
                      mostRecentCard ? mostRecentCard.last4 : ''
                    }`}
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.white}
                  />
                }
                onClick={() => {
                  // Show Card Payment modal with the details from this card
                  // already pre-populated
                  // setShowCardPaymentModal(true);
                  // onCardPayment();

                  if (expiryTime > 0 && expiryTime < new Date().getTime()) {
                    purchaseExpired();
                  } else {
                    // CVV logic
                    /*if (mostRecentCard.verification_type === 'cvv') {
                      setShowCvvModal(true);
                    } else {
                      //submitHandler();
                    }*/
                    setShowCvvModal(true);
                  }
                }}
              />
              <Spacer height={25} />
              <FirstButton
                className="add-new-card-button"
                height={45}
                borderRadius={50}
                borderColor={theme.colors.white}
                labelColor={theme.colors.white}
                tabIndex={1001}
                label={
                  <TextContent
                    text="Add a new card"
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.white}
                  />
                }
                onClick={() => {
                  onAddCard(mostRecentCard.cardId);
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div style={{width: '352px', display: showCardWrapper ? 'block' : 'none'}}>
              <FirstButton
                className="add-new-card-button"
                height={45}
                borderRadius={50}
                borderColor={theme.colors.white}
                labelColor={theme.colors.white}
                tabIndex={1001}
                label={
                  <TextContent
                    text="Add a new card"
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.white}
                  />
                }
                onClick={() => {
                  onAddCard();
                }}
              />
            </div>
          </>
        )}
      </PaymentRow>
    );
  }

  function three_d_secure_window() {
    return (
      <ThreeDSDiv>
        <iframe
          id="3ds-frame"
          className="threeds-redirect-frame"
          src={threeDSLink}
          width={'100%'}
          height={400}></iframe>
      </ThreeDSDiv>
    );
  }

  function loading_screen() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: viewport.width < 576 ? '290px' : '434px',
          marginTop: '180px',
          marginLeft: viewport.width < 576 ? '40px' : '-40px',
          paddingLeft: '0px',
        }}>
        <BeatLoader color="white" loading={isAwaitingPaymentConfirmation} />
      </div>
    );
  }

  function loading_stripe() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: viewport.width < 576 ? '290px' : '350px',
          paddingLeft: '0px',
          maxHeight: "45px",
          marginTop: "13px",
          marginBottom: "13px"
        }}>
        <BeatLoader color="white" loading={true} />
      </div>
    );
  }

  function payment_attempts_exceeded() {
    return (
      <AttemptsExceededContainer>
        <BoldText
          text="Payment Failed!"
          fontSize="fz24"
          fontColor={theme.colors.yellow}
        />
        <ExceededTextContainer>
          <Typography
            text="Sorry, your payment has failed three times. Please reselect your bits to try buying again."
            fontSize="fz16"
            lineHeight="26px"
          />
        </ExceededTextContainer>
        <Spacer height={5} />
        <FirstButton
          label="Start Again"
          fontSize={16}
          bgColor={theme.colors.yellow}
          labelColor={theme.colors.black}
          width={357}
          height={45}
          borderRadius={50}
          onClick={() => {
            setShowExceededAttempts(false);
            onStartAgain();
          }}
        />
      </AttemptsExceededContainer>
    );
  }

  function getModalContent() {
    if (show3dsFrame) {
      return three_d_secure_window();
    } else if (isAwaitingPaymentConfirmation) {
      return loading_screen();
    } else if (showExceededAttempts) {
      return payment_attempts_exceeded();
    } else {
      return main_content();
    }
  }

  function getModalTitle() {
    if (show3dsFrame) {
      return 'card verification';
    } else {
      return 'reservation';
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setShow3dsFrame(false);
      setThreeDSFlowInProgress(false);
      setIsAwaitingPaymentConfirmation(false);
    }
  }, [show3dsFrame, isAwaitingPaymentConfirmation, isOpen]);

  return (
    <SBModal
      className={'card-auto'}
      isOpen={isOpen}
      width={modalWidth + 'px'}
      mobileWidth="100%"
      height="662px"
      top={getTopPosition('10%')}
      mobileTop="2%"
      withProceedingText={!(isAwaitingPaymentConfirmation || threeDSFlowInProgress)}
      withSettings={false}
      withFeeText={!(isAwaitingPaymentConfirmation || threeDSFlowInProgress)}
      feeBottomPos="55px"
      content={
        <>
          {canShowCloseButton && (
            <CloseButton
              className="card-auto-close-modal-button"
              onClick={() => {
                setShow3dsFrame(false);
                setThreeDSFlowInProgress(false);
                setIsAwaitingPaymentConfirmation(false);
                setShowExceededAttempts(false);
                setExpressCheckoutOptions(null);
                onClose();
              }}>
              X
            </CloseButton>
          )}

          <BoldText
            text={getModalTitle()}
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />
          {!(isAwaitingPaymentConfirmation || threeDSFlowInProgress) ? (
            <>
              {header}
              <Spacer height={25} />
            </>
          ) : (
            <></>
          )}

          {getModalContent()}

          <Elements stripe={stripePromises[artistPlatform]}>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <SBModal
                  className={'cvv'}
                  isOpen={showCvvModal}
                  width={modalWidth + 'px'}
                  mobileWidth="100%"
                  height="662px"
                  mobileHeight='90%'
                  top={getTopPosition('10%')}
                  mobileTop="2%"
                  withProceedingText={!(isAwaitingPaymentConfirmation || threeDSFlowInProgress)}
                  withSettings={false}
                  withFeeText={!(isAwaitingPaymentConfirmation || threeDSFlowInProgress)}
                  feeBottomPos="55px"
                  content={
                    <>
                      <BoldText
                        text={getModalTitle()}
                        fontWeight="bold"
                        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
                      />
                      <Spacer height={20} />
                      {header}
                      <CVVModal
                        stripe={stripe} elements={elements}
                        onClose={() => {
                          setShowCvvModal(false);
                        }}
                        purchaseExpired={() => {
                          purchaseExpired();
                        }}
                        last4={mostRecentCard ? mostRecentCard.last4 : ''}
                        onEncryptedCvv={(encryptedCvv: StripeCVVResponse) => {
                          setIsAwaitingPaymentConfirmation(true);
                          setShowCvvModal(false);
                          submitHandler(encryptedCvv);
                        }}
                      />
                    </>
                  }
                />
              )}
            </ElementsConsumer>
          </Elements>
        </>
      }
    />
  );
};

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const FirstButton = styled(Button)`
  padding: 0 60px;
  width: 100%;
  border-width: 1px;

  @media (min-width: 576px) {
    width: 350px;
  }
`;

const PayWithCardButton = styled(Button)`
  padding: 0 60px;
  border-width: 1px;
  width: 100%;

  @media (min-width: 576px) {
    width: 350px;
  }
`;

const PaymentRow = styled.div`
  gap: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const AttemptsExceededContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 15px;
  margin-top: -25px;
`;

const ExceededTextContainer = styled.div`
  padding: 0 40px;
  margin-top: -5px;
`;

export const ThreeDSDiv = styled.div`
  width: 100%;
  height: 400px;
  border: 0;
  background: transparent;
`;

export const ThreeDSMethodDiv = styled.div`
  width: 0;
  height: 0;
  border: 0;
  background: transparent;
`;

export default memo(CardAuto);
