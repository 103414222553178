import { gql } from '@apollo/client';
import { GraphQueryDefinition } from 'services/graph.service';

const AUTH_EMAIL_INPUT = {
  email: {type: "string", format: "email"},
  password: {type: "string"},
}
const AUTH_INPUT = {
  username: {type: "string", graph: "Username"},
  password: {type: "string"},
}
const AUTH_RESPONSE = {
  auth: [
    'accessToken',
    'refreshToken',
    'isEmailConfirmed',
    'redirectToGiveaway',
    'redirectAfterAuth',
    'kycStatus',
    'fcaStep',
    'suiEnabled',
  ]
}

/*export const AUTHENTICATE_MUTATION = gql`
  mutation AUTHENTICATE($username: Username!, $password: String!) {
    authenticate(input: { username: $username, password: $password }) {
      auth {
        accessToken
        refreshToken
        isEmailConfirmed
        redirectToGiveaway
        redirectAfterAuth
        kycStatus
        fcaStep
      }
    }
  }
`;*/

/*export const AUTHENTICATE_EMAIL_MUTATION = gql`
  mutation AUTHENTICATE($email: Email!, $password: String!) {
    authenticateEmail(input: { email: $email, password: $password }) {
      auth {
        accessToken
        refreshToken
        isEmailConfirmed
        redirectToGiveaway
        kycStatus
        fcaStep
      }
    }
  }
`;*/

export const authenticate = new GraphQueryDefinition({
  mutation: true,
  auth: false,
  input: AUTH_INPUT,
  output: AUTH_RESPONSE,
});
export const authenticateEmail = new GraphQueryDefinition({
  mutation: true,
  auth: false,
  input: AUTH_EMAIL_INPUT,
  output: AUTH_RESPONSE,
});

export const REGISTER_USER = gql`
  mutation REGISTER_USER(
    $username: String!
    $email: Email!
    $password: PasswordType!
    $country: Int!
    $county: Int
    $waitlist_token_id: UUID
    $artist: UUID
    $song: UUID
    $utm: JSON
  ) {
    registerUser(
      input: {
        _username: $username
        _email: $email
        _password: $password
        _country: $country
        _county: $county
        _waitlistTokenId: $waitlist_token_id
        _artist: $artist
        _song: $song
        _utm: $utm
      }
    ) {
      results {
        tokenId
        auser {
          id
        }
      }
      clientMutationId
    }
  }
`;

export const registerUser = new GraphQueryDefinition({
  mutation: true,
  auth: false,
  input: {
    username: {type: "string"},
    email: {type: "string", format: "email"},
    password: {type: "string", graph: "PasswordType"},
    country: {type: "integer"},
    county: {type: "integer", optional: true},
    waitlistTokenId: {type: "string", format: "uuid", optional: true},
    artist: {type: "string", format: "uuid", optional: true},
    song: {type: "string", format: "uuid", optional: true},
    utm: {type: "string", optional: true, graph: "JSON"},
  },
  output: {
    results: {
      tokenId: null,
      auser: ['id'],
    }
  },
  serializer: {
    tokenId: `results/tokenId`,
    userId: `results/auser/id`,
  }
});

export const REGISTER_USER_WAITLIST = gql`
  mutation REGISTER_USER_WAITLIST(
    $username: String!
    $email: Email!
    $password: PasswordType!
    $country: Int!
    $tokenId: UUID!
  ) {
    registerUserWaitlist(
      input: {
        username: $username
        _email: $email
        password: $password
        country: $country
        tokenId: $tokenId
      }
    ) {
      user {
        id
      }
      clientMutationId
    }
  }
`;

export const REGISTER_USER_WAITLIST_USA = gql`
  mutation REGISTER_USER_WAITLIST_USA(
    $username: String!
    $email: Email!
    $password: PasswordType!
    $country: Int!
    $county: Int!
    $tokenId: UUID!
  ) {
    registerUserUsaWaitlist(
      input: {
        username: $username
        _email: $email
        password: $password
        country: $country
        county: $county
        tokenId: $tokenId
      }
    ) {
      user {
        id
      }
      clientMutationId
    }
  }
`;

export const CONFIRM_EMAIL = gql`
  mutation ConfirmationEmail($token: String!) {
    userConfirmationEmail(input: { tokenValue: $token }) {
      auth {
        accessToken
        isEmailConfirmed
        isInternal
        redirectAfterAuth
        redirectToGiveaway
        kycStatus
        fcaStep
      }
    }
  }
`;

export const userConfirmationEmail = new GraphQueryDefinition({
  name: `ConfirmationEmail`,
  mutation: true,
  auth: false,
  input: {
    tokenValue: {type: "string"},
  },
  output: AUTH_RESPONSE,
});

// Verification Email / Resend Verification Email
export const VERIFICATION_EMAIL = gql`
  mutation VerificationEmail($email: Email!) {
    userSendConfirmationEmail(input: { emailValue: $email }) {
      clientMutationId
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail($expiredTokenId: UUID, $email: Email) {
    userResendConfirmationEmail(input: { expiredTokenId: $expiredTokenId, emailAddress: $email }) {
      responseType {
        message
        status
      }
    }
  }
`;

export const userResendConfirmationEmail = new GraphQueryDefinition({
  name: `ResendVerificationEmail`,
  mutation: true,
  auth: false,
  input: {
    expiredTokenId: {type: "string", format: "uuid"},
    emailAddress: {type: "string", format: "email"},
  },
  output: AUTH_RESPONSE,
});

export const VERIFICATION_EMAIL_CONFIRM = gql`
  mutation VerifyEmail($tokenValue: String!) {
    userConfirmationEmail(input: { tokenValue: $tokenValue }) {
      clientMutationId
    }
  }
`;

export const CHANGE_EMAIL = gql`
  mutation ChangeEmail($userId: UUID!, $email: Email!) {
    userChangeEmailRequest(input: { userId: $userId, newEmail: $email }) {
      clientMutationId
    }
  }
`;

export const userChangeEmailRequest = new GraphQueryDefinition({
  name: `ChangeEmail`,
  mutation: true,
  auth: false,
  input: {
    userId: {type: "string", format: "uuid"},
    newEmail: {type: "string", format: "email"},
  }
});

export const CHANGE_EMAIL_CONFIRM = gql`
  mutation ChangeEmailConfirm($tokenValue: String!) {
    userChangeEmailRequestConfirmation(input: { tokenValue: $tokenValue }) {
      clientMutationId
    }
  }
`;

export const CHANGE_USERNAME = gql`
  mutation ChangeUsername($userId: UUID!, $username: Username!) {
    userUpdateUsername(input: { userid: $userId, newUsername: $username }) {
      clientMutationId
    }
  }
`;

export const userUpdateUsername = new GraphQueryDefinition({
  mutation: true,
  auth: true,
  input: {
    userid: {type: "string", format: "uuid"},
    newUsername: {type: "string"},
  }
});

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: Email!) {
    userSendResetPassword(input: { emailValue: $email }) {
      responseType {
        status
        message
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($tokenValue: String!, $password: PasswordType!) {
    userUpdatePasswordByToken(
      input: { tokenValue: $tokenValue, newPassword: $password }
    ) {
      clientMutationId
    }
  }
`;

/*export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(input: { refreshToken: $refreshToken }) {
      auth {
        accessToken
        refreshToken
      }
    }
  }
`;*/
export const refreshToken = new GraphQueryDefinition({
  mutation: true,
  auth: false,
  input: {
    refreshToken: {type: "string"},
  },
  output: AUTH_RESPONSE,
});

export const CHECK_WAITLIST_TOKEN = gql`
  mutation CHECK_WAITLIST_TOKEN($tokenId: UUID!, $emailAddress: Email!) {
    isWaitlistTokenValid(
      input: { emailAddress: $emailAddress, tokenId: $tokenId }
    ) {
      boolean
    }
  }
`;
