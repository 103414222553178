import React, { useMemo, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useViewport } from 'use-viewport';
import jwtDecode from 'jwt-decode';
import { useStoreState } from 'store/store';
import styled from 'styled-components';

import PageLayout from 'common/components/PageLayout/PageLayout';
import PageContainer from 'common/layout/PageContainer';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import Dropdown from '../components/Dropdown/Dropdown';
import DropData from './components/DropData';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';
import Drops from '../components/Drops/Drops';

import { GET_ALL_DROPS } from 'modules/artist/graphql/Queries.graphql';
import { footerHeight, footerMobileHeight } from 'utils/constants';
import ArtistReleasesPlaceholder from 'common/components/Placeholder/ArtistReleases';

const quickLinks = [
  {
    title: 'Artist Profile',
    link: '/artist/settings',
  },
  {
    title: 'Artist Dashboard',
    link: '/artist/dashboard',
  },
  {
    title: 'Insights & Analytics',
    link: '/artist/insights',
  },
  {
    title: 'View Users',
    link: '/artist/insights/users',
  },
  {
    title: 'Accounting Management',
    link: '/artist/accounting/management',
  },
  {
    title: 'Payouts',
    link: '/artist/accounting/payouts',
  },
  {
    title: 'Artist Support (A&R)',
    link: '/support',
  },
  {
    title: 'Marketing Tools',
    link: '/artist/marketing-tools',
  },
];

const ArtistReleases = () => {
  const viewport = useViewport();

  const accessToken = useStoreState(
    (state) => state.authentication.auth.accessToken,
  );

  const {
    data: dropsData = {
      allSongs: {
        nodes: [],
      },
    },
  } = useQuery(GET_ALL_DROPS, {
    variables: {
      userId: jwtDecode<{ user_id: string }>(accessToken ?? '').user_id,
    },
    fetchPolicy: 'network-only',
  });

  const DropsList_dropdown = useMemo(
    () => [
      {
        label: 'All Releases',
        value: 'all',
      },
      ...dropsData.allSongs.nodes.map((dropData: any) => ({
        label: dropData.title,
        value: dropData.slug,
      })),
    ],
    [dropsData],
  );

  const [loading, setLoading] = useState(true);
  const [currentDrop, setCurrentDrop] = useState(DropsList_dropdown[0]);

  useEffect(() => dropsData?.allSongs?.nodes && setLoading(false), [dropsData]);

  const firstColumn = (
    <>
      {loading ? (
        <>
          <ArtistReleasesPlaceholder />
        </>
      ) : (
        <ContentContainer>
          <Dropdown
            data={DropsList_dropdown}
            currentItem={currentDrop}
            onSelect={(item) => setCurrentDrop(item)}
          />

          <DropsList>
            {dropsData.allSongs.nodes.map((dropData: any, index: number) => {
              return currentDrop.value === 'all' ||
                currentDrop.value === dropData.slug ? (
                <DropData key={`Drop_${index}`} data={dropData} />
              ) : null;
            })}
          </DropsList>
        </ContentContainer>
      )}
    </>
  );
  const secondColumn = (
    <ContentContainer>
      <Drops drops={[]} isArtist={true} showLinkToAllDrops={false} />

      {loading && viewport.width >= 767 ? (
        <QuickLinksPlaceholder></QuickLinksPlaceholder>
      ) : (
        <>{viewport.width <= 767 ? <></> : <QuickLinks links={quickLinks} />}</>
      )}
    </ContentContainer>
  );

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}
      pageTitle={'Artist Releases | SongBits'}>
      <PageLayout
        title="All Releases"
        is2ColumnLayout
        sections={[
          {
            content: firstColumn,
          },
          {
            content: secondColumn,
          },
        ]}
      />
    </PageContainer>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropsList = styled.div`
  margin-top: 25px;
`;

export default ArtistReleases;
